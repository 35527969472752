import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconInstagram } from './assets/icons/instagram.svg';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href="."><img src={require('./assets/icons/ok.png')} alt="Qries" width="100"/> </a>
          </div>
          <div className="social">
            <a href="https://www.facebook.com/profile.php?id=100068140765742" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://www.instagram.com/_yun_shop/" title="Instagram" target="_blank" rel="noopener noreferrer">
              <IconInstagram className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Siete pronti ?! 😋</h1>
            <p>Il nuovo sito e una grossa novità arriveranno presto. Scriveteci pure una mail se siete curiosi. </p>
          </div>
          <a href="mailto:info@yunshop.it">
            <div className="cta">Scrivici Ora</div>
          </a>
        </div>
        <div className="footer">
          <span>Build with 💜 by <a className="underlined" href="https://www.federicomengascini.com" target="_blank" rel="noopener noreferrer">Federico</a></span>
        </div>
      </div>
    );
  }
}

export default App;